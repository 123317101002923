import React, { useState, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Faq from '../components/Faq/index'
import FooterImages from '../components/Blocks/FooterImages'
import { WORDPRESS_URL } from '../config'

const PageTemplate = ({ content, faq, footerImages }) => {
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    const handleClick = e => {
      if (e.target?.href?.includes('#openPrintModal')) {
        e.preventDefault()
        setModalOpen(true)
      }
    }
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  return (
    <div className="w-full">
      <div
        className={'transition-all page-content'}
        dangerouslySetInnerHTML={{ __html: content }}
      />
      {faq && <Faq title={faq?.title} items={faq?.faqs} />}
      <FooterImages items={footerImages} />

      <div
        onClick={() => setModalOpen(false)}
        className={`${
          modalOpen
            ? `opacity-100 pointer-events-auto`
            : `pointer-events-none opacity-0`
        } fixed inset-0 bg-black bg-opacity-70 z-50 transition-all flex justify-center items-center`}
      >
        <div className="w-full max-w-xl">
          <div className="bg-brand-blue rounded-t p-4 flex items-center justify-center">
            <h3 className="font-thin text-white text-center mb-0">
              The Fine Print
            </h3>
          </div>
          <div className="bg-white rounded-b p-4">
            <p>
              Closed-End Lease. Excessive wear and tear fees apply. $0.15 per
              mile for all mileage over 12000 miles per year, unless waived by
              D&M Leasing. $ 500 disposition fee is due at the end of the lease.
              Lessee is responsible for the maintenance of the vehicle and for
              obtaining auto insurance for the vehicle for the duration of the
              lease. If any payment is late, late fees and other penalties will
              be imposed. Vehicle referenced in advertisement is not in
              possession of D&M Leasing; D&M Leasing sources it’s vehicles from
              a variety of dealerships, including affiliated dealerships.
              Vehicles depicted in advertisement is a representation of the make
              and model generally available; certain features, such as exterior
              color and/or trim level, may vary dependent upon availability.
              Total amount paid during the lease term is the monthly payment
              multiplied by the lease term and added to the amount due at
              signing.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const CarLeaseDeals = ({ data, pageContext }) => {
  const { wpPage: page } = data
  return (
    <Layout pageContext={pageContext}>
      <Seo seo={page.seo} />
      <Helmet>
        <script type="application/ld+json">
          {`[{"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"How to get the best car lease deal?","acceptedAnswer":{"@type":"Answer","text":"D&amp;M Leasing is the most competitive and largest leasing company in the nation, which is why we can offer the lowest rates and best deal on your new auto lease. Our agents take the hassle out of shopping for a new vehicle for you. We buy over 1,000 vehicles per month and pass all savings and rebates on to you. Buying in these large of quantities is what allows us to give our customers the best leasing deals on the market."}},{"@type":"Question","name":"Should I lease a used car versus a new car?","acceptedAnswer":{"@type":"Answer","text":"There are advantages to both new and used car leasing. New car leasing is beneficial for those who want the latest model vehicle with the most up to date trends, without the high price tag or a long-term commitment. Used car leasing can be more cost-effective if you are looking for affordable payments and low commitment. Read more about our used car lease deals."}},{"@type":"Question","name":"Does D&M offer car lease deals?","acceptedAnswer":{"@type":"Answer","text":"Yes! D&amp;M offers monthly car lease deals with no cash due at signing and no payments for 90 days with approved credit. Our agents will shop the market and find you the best lease deal on any make or model. Call today!"}}]},{"@context":"https://schema.org/","@type":"Product","name":"2022 Toyota Tacoma 2WD Double Cab SR5","image":"${WORDPRESS_URL}/wp-content/uploads/2021/11/DM_WebsiteImage_2022_Tacoma_900x900-1.jpg","description":"$389/MONTHLY PMT<br>MSRP: $34,095</br>No Payments for 90 Days","offers":{"@type":"Offer","url":"https://www.dmautoleasing.com/car-lease-deals/","priceCurrency":"USD","price":"34095","availability":"https://schema.org/InStock"}}]`}
        </script>
      </Helmet>
      <PageTemplate
        title={page.title}
        content={page.content}
        faq={page.faq}
        footerImages={page?.footerImages?.items}
      />
    </Layout>
  )
}

export default CarLeaseDeals

export const pageQuery = graphql`
  query CarLeaseDeals($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      seo {
        fullHead
        schema {
          raw
        }
      }
      footerImages {
        items {
          image {
            mediaItemUrl
          }
          buttonLink {
            target
            title
            url
          }
          buttontext
        }
      }
      faq {
        title
        faqs {
          body
          title
        }
      }
    }
  }
`
