import React from 'react'
import { Link } from 'gatsby'

const FooterImages = ({ items }) => (
  <div className="grid grid-cols-1 md:grid-cols-2 mt-20">
    {items.map(item => (
      <div
        className="h-500 bg-center bg-cover flex items-center justify-center relative"
        key={item.buttontext}
      >
        <Link
          to={item.buttonLink.url}
          className="text-white border border-white rounded-full text-lg uppercase px-8 py-6 hover:bg-white hover:text-brand-blue transition-all z-10"
        >
          {item.buttontext}
        </Link>
        <img
          src={item.image.mediaItemUrl}
          alt={item.image.alt}
          className="inset-0 absolute h-full w-full object-cover"
        />
      </div>
    ))}
  </div>
)

export default FooterImages
